@import "../../assets/variables";

.about {
  font-size: 14px;
}

.legal {
  margin: 0;
  font-size: 12px;
  color: var(--light-2);

  span {
    color: var(--accent);
  }
}

.github {
  // display: flex;
  // align-items: center;
  svg {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
  }
}

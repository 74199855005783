
@import "../../assets/variables.scss";
.isUploadPng{
    &:hover, &.active{
         background-color:  var(--accent) !important;
         color:#000;
    }
}

.isUploadPngActive{
         background-color:  var(--accent) !important;
         color:#000;
}
@import "./assets/variables";

@font-face {
  font-family: "legends";
  src: url("/fonts/legends.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
*:focus,
*:active {
  outline: none;
  box-shadow: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: "Inter var", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $color-main;
}
#screen {
  position: relative !important;
  transform: none !important;
  &:before {
    display: none !important;
  }
}

#portal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

//simulate hover on a canvas with a body class toggle
body.intersecting-key.editing #canvas-wrapper {
  cursor: url("/paintbrush.png") 0 47, auto !important;
}
.grabable {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}







@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


#successAnimationCircle {
  stroke-dasharray: 151px 151px;
  stroke: #FFF;
}

#successAnimationCheck {
  stroke-dasharray: 36px 36px;
  stroke: #FFF;
}

#successAnimationResult {
  fill: #FFF;
  opacity: 0;
}

#successAnimation.animated {
  animation: 1s ease-out 0s 1 both scaleAnimation;
  
  #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
               0.3s linear 0.9s 1 both fadeOut;
  }
  
  #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
               0.3s linear 0.9s 1 both fadeOut;
  }
  
  #successAnimationResult {
    animation: 0.3s linear 0.9s both fadeIn;
  }
}

#replay {
  background: rgba(255,255,255,0.2);
  border: 0;
  border-radius: 3px;
  bottom: 100px;
  color: #FFF;
  left: 50%;
  outline: 0;
  padding: 10px 30px;
  position: absolute;
  transform: translateX(-50%);
  &:active {
    background: rgba(255,255,255,0.1);
  }
}